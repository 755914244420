/* About.css */
.about-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 5rem;
  background-color: white;
}

.headshot-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.section-card {
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  transition: transform 0.2s ease;
}

.section-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.skill-tag {
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  padding: 0.75rem;
  text-align: center;
  transition: all 0.2s ease;
}

.skill-tag:hover {
  background-color: #e2e8f0;
  transform: translateY(-2px);
}